import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Chip, Button } from '../../src';
import Check from '../../src/Icons/Check.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "chip"
    }}>{`Chip`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Chip from 'mfcl/Chip'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Chips allow users to enter information, make selections, filter content, or trigger actions.`}</p>
    <p>{`While included here as a standalone component, the most common use will be in some form of input, so some of the behaviour demonstrated here is not shown in context.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Chip} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Chip label=\"Outlined\" variant=\"outlined\" />\n<Chip label=\"Default\" variant=\"default\" />\n<Chip label=\"Filled\" variant=\"filled\" />\n<Chip label=\"Checked\" variant=\"checked\" />\n<Chip label=\"Check Icon\" rightIcon={<Check />} />\n<Chip label=\"Small\" size=\"sm\" />\n<Chip label=\"Medium\" size=\"md\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Chip,
      Button,
      Check,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Chip label="Outlined" variant="outlined" mdxType="Chip" />
  <Chip label="Default" variant="default" mdxType="Chip" />
  <Chip label="Filled" variant="filled" mdxType="Chip" />
  <Chip label="Checked" variant="checked" mdxType="Chip" />
  <Chip label="Check Icon" rightIcon={<Check mdxType="Check" />} mdxType="Chip" />
  <Chip label="Small" size="sm" mdxType="Chip" />
  <Chip label="Medium" size="md" mdxType="Chip" />
    </Playground>
    <h2 {...{
      "id": "deletable-list"
    }}>{`Deletable List`}</h2>
    <Playground __position={2} __code={'<Component\n  initialState={{\n    chipData: [\n      \'$100-$500\',\n      \'$501-$1000\',\n      \'$1001-$1500\',\n      \'$1501-$2000\',\n      \'$2000+\',\n    ],\n  }}\n>\n  {({ setState, state }) => {\n    const handleDelete = chipLabel => {\n      setState({\n        chipData: state.chipData.filter(chip => chip !== chipLabel),\n      })\n    }\n    return (\n      <>\n        <ul\n          style={{\n            display: \'flex\',\n            alignItems: \'center\',\n            flexWrap: \'wrap\',\n            listStyle: \'none\',\n          }}\n        >\n          {state.chipData.map((chip, i) => (\n            <li key={chip}>\n              <Chip label={chip} onDelete={handleDelete} />\n            </li>\n          ))}\n          <li>\n            <Button\n              onClick={() => setState({ chipData: [] })}\n              size=\"sm\"\n              btnType=\"link\"\n            >\n              Clear All\n            </Button>\n          </li>\n        </ul>\n        <Button\n          onClick={() =>\n            setState({\n              chipData: [\n                \'$100-$500\',\n                \'$501-$1000\',\n                \'$1001-$1500\',\n                \'$1501-$2000\',\n                \'$2000+\',\n              ],\n            })\n          }\n          size=\"sm\"\n        >\n          Refresh\n        </Button>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Chip,
      Button,
      Check,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        chipData: ['$100-$500', '$501-$1000', '$1001-$1500', '$1501-$2000', '$2000+']
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          const handleDelete = chipLabel => {
            setState({
              chipData: state.chipData.filter(chip => chip !== chipLabel)
            });
          };

          return <>
          <ul style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              listStyle: 'none'
            }}>
            {state.chipData.map((chip, i) => <li key={chip}>
                <Chip label={chip} onDelete={handleDelete} mdxType="Chip" />
              </li>)}
            <li>
              <Button onClick={() => setState({
                  chipData: []
                })} size="sm" btnType="link" mdxType="Button">
                Clear All
              </Button>
            </li>
          </ul>
          <Button onClick={() => setState({
              chipData: ['$100-$500', '$501-$1000', '$1001-$1500', '$1501-$2000', '$2000+']
            })} size="sm" mdxType="Button">
            Refresh
          </Button>
        </>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`If the Chip is deletable or clickable then it is a button in tab order.`}</p>
    <p>{`When the Chip is focused (e.g. when tabbing) releasing (`}<inlineCode parentName="p">{`keyup`}</inlineCode>{` event) `}<inlineCode parentName="p">{`Backspace`}</inlineCode>{` or `}<inlineCode parentName="p">{`Delete`}</inlineCode>{` will call the `}<inlineCode parentName="p">{`onDelete`}</inlineCode>{` handler while releasing `}<inlineCode parentName="p">{`Escape`}</inlineCode>{` will blur the Chip.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      